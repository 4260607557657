.sidebar.collapsed-sidebar {
    border: 0;
    box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
    display: none;
    .sidebar-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-radius: 0 20px 20px 0;
        .ui-aside {
            float: left;
            width: 100%;
            max-width: 66px;
            margin-left: 0px;
            color: rgba(255, 255, 255, 0.5);
            @include transition( all .299s);
            @include margin-padding(0, 0);
            box-shadow: 0px 0 14px rgba(198, 198, 198, 0.25);
            min-height: 100vh;
            border: 0;
            position: fixed;
            left: 0;
            z-index: 999;
            .nav-tabs {
                @include margin-padding(null, 14px 14px 0);
                border: 0;
            .nav-item {
				margin-bottom: 10px;
                .nav-link {
                    @include margin-padding(null, 5px);
					width: 38px;
					height: 38px;
                    &:hover {
                        background-color: $primary;
                        img {
							filter: brightness(0) invert(1);
                        }
						svg {						
							color: $white;
						}
                    }
                    &:active, &.active {
						background-color: $primary;
                        img {
							filter: brightness(0) invert(1);
                        }
						svg {						
							color: $white;
						}
                        &::after {
                            content: none;
                        }
                        border: 0;
                    }
                    &::before {
                        content: none;
                    }
                }
                &:last-child {
                    .nav-link {
                    border-bottom: 0;
                    }
                }
            }
        }
    }
}
    .tab-content-four {
        padding-top: 20px;
        margin-left: 80px;
        width: 100%;
    }
    ul ul {
        display: none;
    }
    .sidebar-menu {
        @include margin-padding(null, 0 20px 0 0);
        ul li {
            a {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                @include margin-padding(null, 10px 15px 10px 25px);
                position: relative;
                color: $gray-600;
                svg{
                    width: 18px;
                    color: $gray-600;
                }
                img{
                    width:18px;
                    color: $gray-600;
                }
                &:hover{
                    color: $primary;
                    img {
                        filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
                    }
                    span{
                        color: $primary;
                    }
                    svg{
                        color: $primary;
                    }
                }
                &::before {
                    content: "";
                    @include position(absolute, 50%, null, null, 10px);
                    transform: translateY(-50%);
                    background: $gray-500;
                    width: 5px;
                    height: 5px;
                }
                &.active{
                    color: $primary;
                    svg{
                        color: $primary;
                    }
                    img {
                        filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
                    }
                    span{
                        color: $primary;
                    }
                }
                span{
                    margin-left: 0;
                    font-size: $font-size-15;
                    font-weight: $font-weight-medium;
                    color: $gray-600;
                }
                .menu-arrow {
                    display: inline-block;
                    font-family: "Font Awesome 5 Free";
                    text-rendering: auto;
                    line-height: 40px;
                    font-size: $font-size-18;
                    line-height: 18px;
                    @include position(absolute,12px,0px,null,null);
                    @include transform(translate(0, 0));
                    @include transition(all 0.2s ease);
                    &.inside-submenu {
                        right: 15px;
                        &.inside-submenu-two {
                            right: 15px
                        }
                    }
                }
                &.subdrop{
                    .menu-arrow {
                        @include transform(rotate(90deg));
                    }
                }
            }
            &.submenu.submenu-two {
                ul {
                    li {
                        a {
                            margin-left: 10px;
                        }
                    }
                }
            }
            &.submenu.submenu-three {
                ul {
                    li {
                        a {
                            margin-left: 20px;
                        }
                    }
                }
            }
            ul {
                li {
                    a {
                        @include margin-padding(null, 10px 15px 10px 25px);
                        left: 14px;
                    }
                }
            }
        }
    }
}
.header-four {
    .header-left {
        border-right: 0 !important;
    }
}
.page-wrapper-four {
    margin-left: 250px;
}

@include respond-below(custom992) {
    .page-wrapper-four {
        margin-left: 0;

    } 
}
