.btn-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.pos-design {
    .btn-row {
        padding: 24px;
        a {
            display: flex;
            align-items: center;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .pos-wrapper {
        --bs-gutter-x: 0;
    }
}
.btn-row {
    @include respond-below(custom575) {
        flex-direction: column;
    }
    .btn {
        font-size: $font-size-base;
        color:  $white;
        @include margin-padding(0 10px 0 0, 10px);
        @include rounded(4px);
        @include respond-below(custom575) {
            margin: 0 0 15px 0;
            width: 100%;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.pos-categories, .order-list {
    h5, h6 {
        color: $secondary;
    }
    h5 {
        font-size: $font-size-18;
        font-weight: $font-weight-bold;
    }
}
.btn-info {
    background-color: $blue;
    border-color: $blue;
    &:hover {
        background-color: $primary;
        border-color: $primary;
    }
}
.btn-primary {
    background-color: $primary;
    border-color: $primary;
    &:hover {
        background-color: $secondary;
        border-color: $secondary;
    }
    @include respond-below(custom991)
		{
			margin-top: 10px;
				}
    @include respond-below(custom767)
		{
			margin-top: 10px;
				}
                @include respond-below(custom575)
		{
			margin-top: 10px;
				}
}
.btn-secondary {
    background-color: $secondary;
    border-color: $secondary;
    &:hover {
        background-color: $primary;
        border-color: $primary;
    }
}
.btn-danger {
    background-color: $danger;
    border-color: $danger;
    &:hover {
        background-color: $secondary;
        border-color: $secondary;
    }
}
.btn-success {
    background-color: $success;
    border-color: $success;
    &:hover {
        background-color: $secondary;
        border-color: $secondary;
    }
}
.owl-carousel {
    .owl-nav {
        margin: 0;
        @include position(absolute,-60px,0,null,null);
        button {
            margin-top: 0;
            margin-bottom: 0;
            &.owl-next, &.owl-prev {
                position: relative;
                width: 28px;
                height: 28px;
                @include rounded(28px);
                i {
                    color:  $primary;
                }
                &:hover {
                    background-color: $secondary;
                    border-color: $secondary;
                }
            }
        }
    }
}
.pos-categories {
    background-color: $light;
    padding: 24px;
    border-collapse: collapse;
}

.pos-category {
    margin-bottom: 24px;
    .owl-item {
        background-color: $white;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        text-align: center;
        cursor: pointer;
        border: 1px solid $gray-100;
        box-shadow: $box-shadow;
        @include transition(all 0.5s ease);
        @include rounded(8px);
        margin-left: -0.1px!important;
        li {
            padding: 15px 0;
        }
        a {
            img {
                @include transition(all 0.5s ease);
                min-width:  40px;
                width: 40px;
                height: 40px;
                margin: 0 auto;
            }
        }
        h6 {
            font-size: $font-size-15;
            font-weight: $font-weight-semibold;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-top: 10px;
            width: 100%;
            margin-bottom: 5px;
            a {
                color: $secondary;
            }
        }
        span {
            color:  $text-color;
        }
        &:hover {
            border-color: $primary;    
            border-radius: 5px;
            h6 {
                a {
                    color:  $primary;
                }
            }
            a {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }
    .owl-nav {
        button {
            &.owl-next {
                margin-left: 5px;
            }
            &.owl-prev { 
                margin-right: 5px;
            }
            &.owl-next, &.owl-prev {
                background-color: $white;
                border:  1px solid $gray-100;
                i {
                    color:  $secondary;
                }
                &:hover {
                    background-color: $primary;
                    border-color: $primary;
                    i {
                        color: $white;
                    }
                }
            }
        }
    }
}
.pos-products {
    .product-info {
        padding: 15px;
        color:  $sub-title;
        @include transition(all 0.5s ease);
        .img-bg {
            height: 117px;
            background-color: $gray-100;
            @include rounded(10px);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            margin-bottom: 10px;
            position: relative;
            img {
                @include transition(all 0.5s ease);
            }
            span {
                @include transition(all 0.5s ease);
                @include position(absolute,0,0,null,null);
                width: 18px;
                height: 18px;
                @include rounded(100%);
                background: $success;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                justify-content: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                display: none;
                svg {
                    color: $white;
                    @include position(absolute,1px,null,null,1px);
                }
            }
        }
        h6 {
            font-size: $font-size-15;
            font-weight: $font-weight-bold;
            &.cat-name {
                a {
                    color: $sub-title;
                }
            }
            &.product-name {
                a {
                    color: $secondary;
                }
            }
        }
        .price {
            margin-top: 17px;
            span {
                color:  $purple;
            }
            p {
                color: $primary;
            }

        }
        &:hover, &.active {
            border-color: $primary;
            .img-bg {
                img {
                    transform: scale(1.2);
                }
                span {
                    display: block;
                }
            }
        }
    }
}
.product-wrap{ 
    height: 34vh;
    overflow: auto;

    .product-list {
        box-shadow: $box-shadow;
        @include margin-padding(0 0 10px 0, 10px);
        .product-info {
            width: 60%;
            .img-bg {
                width: 83px;
                height: 83px;
                background-color: $body-bg;
                @include rounded(10px);
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                justify-content: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                margin-right: 10px;
            }

        }
        .info {
            span {
                background-color: $primary;
                @include rounded(3px);
                font-weight: $font-weight-semibold;
                color: $white;
                font-size: $font-size-base;
                padding: 0 10px;
                min-width: 64px;
            }
            h6 {
                font-size: $font-size-base;
                font-weight: $font-weight-bold;
                margin-bottom: 0;
                a {
                    color: $secondary;
                }
            }
            p {
                font-size: $font-size-15;
                font-weight: $font-weight-semibold;
                color: $text-color;
            }
        }
        .qty-item {
            position: relative;
            margin-right: 7px;
            input {
                padding: 1px 9px;
                background: $body-bg;
                @include rounded(8px);
                height: 28px;
                width: 71px;
                text-align: center;
                font-size: $font-size-base;
            }
            .dec, .inc {
                position: absolute;
                top: 50%;
                @include transform(translateY(-50%));
                color: $secondary;
                @include transition(all 0.5s ease);
                &:hover {
                    color: $primary;
                }
            }
            .dec {
                left: 9px;
            }
            .inc {
                right: 9px;
            }
        }
        .action {
            a {
                padding: 7px;
                @include rounded(8px);
                border: 1px solid $gray-100;
                cursor: pointer;
                &.edit-icon {
                    color: $blue;
                    &:hover {
                        background-color: $blue;
                        border-color: $blue;
                        color: $white;
                    }
                }
                &.delete-icon {
                    color: $danger;
                    &:hover {
                        background-color: $danger;
                        border-color: $danger;
                        color: $white;
                    }
                }
            }
        }
    }
}
aside {
    height: 100%;
    padding: 24px;
    background-color: $white;
    border-left: 1px solid $gray-100;
    &.product-order-list {
        h6 {
            font-size: $font-size-16;
            font-weight: $font-weight-semibold;
            margin-bottom: 20px;
            color: $title-color;
        }
        .block-section {
            @include margin-padding(20px 0 20px 0, 0 0 20px 0);
            border-bottom: 1px solid $gray-100;
        }
        .head {
            background-color: $body-bg;
            @include rounded(8px);
            padding: 10px;
            h5 {
                color: $secondary;
                font-size: $font-size-18;
                font-weight: $font-weight-bold;
            }
            span {
                color: $text-color;
                font-size: $font-size-15;
                font-weight: $font-weight-medium;
            }
        }
        .customer-info {
            .input-block {
                margin-bottom: 10px;
                .btn {
                    min-width: 40px;
                    min-height: 40px;
                    @include margin-padding(0 0 0 10px, 5px);
                }
            }
        }
        .product-added {
            .head-text {
                margin-bottom: 20px;
                .trash {
                    svg {
                        margin-right: 8px;
                    }
                }
            }
            h6 {
                .count {
                    width: 15px;
                    height: 15px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    background: $primary;
                    @include rounded(100%);
                    color: $white;
                    font-size: $font-size-10;
                    font-weight: $font-weight-semibold;
                    @include margin-padding(0 0 0 7px, null);
                }
            }
        }
        .selling-info {
            margin-bottom: 24px;
            .input-block {
                label {
                    margin-bottom: 5px;
                }
                .select2-container--default .select2-selection--single .select2-selection__rendered {
                    @include rounded(5px);
                    border-color: $navy;
                }
            }
        }
        .order-total{
            background-color: $gray-100;
            padding: 24px;
            @include rounded(8px);
            table {
                tr {
                    td {
                        padding: 0 0 15px 0;
                        color: $text-color;
                        font-size: $font-size-15;
                        font-weight: $font-weight-medium;
                        background: transparent;
                        &.danger {
                            color: $danger;
                        }
                    }
                    &:last-child {
                        td {
                            padding: 20px 0 0 0;
                            font-size: $font-size-16;
                            font-weight: $font-weight-semibold;
                        }
                    }
                }
            }

        }
        .payment-method {
            .methods {
                .item {
                    padding: 0 7px;
                    @include respond-below(custom991) {
                        padding-bottom: 15px;
                    }
                }
                .default-cover {
                    @include transition(all 0.5s ease);
                    a {
                        box-shadow: $box-shadow-sm;
                        @include margin-padding(null, 10px 15px);
                        text-align: center;
                        display: block;
                        font-size: $font-size-15;
                        color: $secondary;
                        span {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: block;
                        }

                    }
                    &:hover {
                        background-color: $gray-200;
                        border-color: $primary;
                        img {
                            filter: invert(75%) sepia(66%) saturate(1955%) hue-rotate(327deg) brightness(103%) contrast(102%);
                        }
                    }
                }
            }
        }
        .btn-block {
            @include margin-padding(20px 0 20px 0, 0 0 20px 0);
            border-bottom: 1px solid $gray-100;
            .btn {
                font-size: $font-size-base;
                font-weight: $font-weight-bold;
                padding: 13px 10px;
            }
        }
        .btn-row {
            padding: 0;
            .btn {
                margin-right: 5px;
                @include rounded(4px);
                font-size: $font-size-base;
                &.btn-info {
                    background-color: $purple;
                    border-color: $purple;
                }
                &.btn-danger {
                    background-color: $danger;
                    border-color: $danger;
                }
                &.btn-success {
                    background-color: $success;
                    border-color: $success;
                }
                &:hover {
                    background-color: $secondary;
                    border-color: $secondary;      
                }
            }
        }
    }
}
#payment-completed {
    .modal-body {
        padding: 40px;
        .icon-head {
            margin-bottom: 10px;
            svg {
                color: $success;
            }
        }
        h4 {
            font-size: $font-size-20;
            font-weight: $font-weight-bold;
        }
        .modal-footer {
            .btn {
                &:hover {
                    color: $white;
                }
            }
        }
    }
}
#print-receipt {
    color:  $text-color;
    .modal-dialog {
        width: 424px;
    }
    .modal-body {
        padding: 30px;
        color: $text-color;
        h6 {
            font-size: $font-size-16;
        }
        .info {
            h6 {
                margin: 10px 0;
                font-weight: normal;
            }
            a {
                color: $text-color;
            }
        }
        .tax-invoice {
            h6 {
                margin: 10px 0;
                font-weight: $font-weight-semibold;
                position: relative;
                &:after, &:before {
                    @include transform(translateY(-50%));
                    content: "";
                    border-top:  1px dashed $text-color;
                    width: 35%;
                }
                &:before {
                    @include position(absolute,50%,null,null,0);
                }
                &:after {
                    @include position(absolute,50%,0,null,null);
                }
            }
            .invoice-user-name {
                margin-bottom: 10px;
            }
            span {
                color: $secondary;
            }
        }
        table {
            thead {
                th {
                    color: $secondary;
                    width: auto;
                    min-width: auto;
                    padding: 5px;
                    border-top: 1px dashed $text-color;
                    border-bottom: 1px dashed $text-color;
                }
            }
            tbody{ 

                tr {
                    td {
                        padding: 5px;
                    }
                    table {
                        border-top: 1px dashed $text-color;
                        tr {
                            &:last-child {
                                td {
                                    font-weight: $font-weight-medium;
                                    font-size: $font-size-15;
                                    color: $secondary;
                                }
                            }
                        }    
                    }
                }
            }
        }
        .invoice-bar {
            border-top: 1px dashed $text-color;
            @include margin-padding(null, 20px 0 0 0);
        }
        .btn {
            width: 200px;
        }
    }
}
.pos-modal {
    .modal-header {
        background-color: $body-bg;
        h5 {
            font-size: $font-size-18;
            font-weight: $font-weight-bold;
        }
    }
    &#hold-order {
        h2 {
            font-size: $font-size-36;
            font-weight: $font-weight-bold;
            color: $gray-900;
            margin-bottom: 10px;
            background-color: $body-bg;
            @include rounded(10px);
        }
        .input-block {
            label {
                margin-bottom: 5px;
            }
        }
        p {
            margin-top: 20px;
        }
    }
    &#recents {
        .table {
            tbody {
                tr {
                    td {
                        padding: 10px 15px;
                    }
                }
            }

        }
    }
    .badge {
        padding: 5px 10px;
        font-size: $font-size-15;
        font-weight: normal;
    }
    &#orders {
        .modal-dialog {
            max-width: 575px;
        }
        .order-body{
            height: 50vh;
            overflow: auto;
            .default-cover {
                border: 1px solid $gray-100;
                box-shadow: $box-shadow;
                @include rounded(8px);
            }
            .record {
                font-size: $font-size-15;
                td {
                    padding-bottom: 15px;
                }
                tr {
                    &:last-child {
                        td {
                            padding-bottom: 0;
                        }
                    }
                }
                .colon {
                    padding: 0 10px;
                    color: $secondary;
                }
                .text {
                    color: $text-color;
                    white-space: nowrap;
                }
            }
            p {
                font-size: $font-size-15;
                background-color: $body-bg;
                @include rounded(8px);
            }
            .search-set {
                .search-input {
                    width: 100%;
                    label {
                        width: 100%;
                        input {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &.upload-modal {
        .modal-body{ 
            .drag-drop {
                position: relative;
                padding: 20px;
                border:  1px dashed $sub-title;
                input[type="file"] {
                    @include position(absolute,0,0,null,0);
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }
            span {
                font-size: $font-size-13;
                font-weight: $font-weight-medium;
                color: $text-color;
            }
            p {
                font-size: $font-size-16;
                font-weight: $font-weight-semibold;
                color: $secondary;
                margin-bottom: 0;
            }
            .progress {
                height: 6px;
                @include rounded(5px);
            }
            ul {
                li {
                    @include margin-padding(0 0 15px 0, 15px);
                    @include rounded(8px);
                    border: 1px solid $gray-100;
                    h6 {
                        a {
                            font-size: $font-size-16;
                            font-weight: $font-weight-semibold;
                            color: $secondary;
                            svg {
                                color:  $success;
                            }
                        }
                        &:hover {
                            a {
                                color: $primary;
                            }
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    &#upload-message {
        .modal-dialog {
            position:fixed;
            bottom:0px;
            right:24px;
            margin:0px;
            left:  auto;
            top: calc(100% - 300px); 
            width: 600px;
        }
        .progress {
            height: 6px;
            @include rounded(5px);
        }
    }
}
label span {
    color: $danger;
}
.wordset {
    ul {
        li {
            a {
                width: 38px;
                height: 38px;
                @include rounded(8px);
                border:  1px solid $gray-100;
                color: $secondary;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
.w-85 {
        width:  85%;
}
.search-order {
    .search-input {
        width: 100%;
    }
    .dataTables_filter {
        label {
            width: 100%;
        }
    }
}
.search-set.search-order .search-input input[type=search] {
    width: 100%;
}
.tabs-sets {
    .wordset {
        li {
            margin-right: 8px;
            margin-left: 0;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}